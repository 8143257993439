import React from "react";
import styles from "./primarybutton.module.css";
export const CustomPrimaryBtn = ({
	handleClick,
	buttonText,
	disabled
}) => {
	return (
		<button
			onClick={handleClick}
			className={`${styles.primarybtn} ${disabled ? styles.disabledPrimaryBtn:" "}`} 
		 >
			{buttonText}
		</button>
	);
};
