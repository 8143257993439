import React from "react";
import { useSelector } from "react-redux";
import styles from "./onboarding.module.css";
import { ProgressBar } from "../../Components/ProgressBar/Progressbar";
import { Header } from "../../Components/Header/header.jsx";
import { Backbutton } from "../../Components/Backbutton/Backbutton.jsx";
import { Page1 } from "../Page1/Page1.jsx";
import { Page2 } from "../Page2/Page2.jsx";
import { Page3 } from "../Page3/Page3.jsx";
import { Page4 } from "../Page4/Page4.jsx";

export const Onboarding = () => {
	const currentStep = useSelector((state) => state.onboarding.currentStep);
	const steps = ["Start", "Conventional Material", "AM Material", "Machine"];
	const ComponentToRender = () => {
		switch (currentStep) {
			case 0:
				return <Page1 />;
			case 1:
				return <Page2 />;
			case 2:
				return <Page3 />;
			case 3:
				 return <Page4 />
			default:
				return null;
		}
	};

	return (
		<div className={styles.mainContainer}>
			<Header />
			<Backbutton />
			<ProgressBar steps={steps} currentStep={currentStep} />
			<ComponentToRender />
		</div>
	);
};
