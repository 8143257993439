import { configureStore } from '@reduxjs/toolkit';
import onboardingReducer from './Slice/onboardingSlice';
import mhrCalculatorReducer from './Slice/mhrSlice';


export const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    mhrCalculator:mhrCalculatorReducer
  }
});
