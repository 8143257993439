import { createSlice } from '@reduxjs/toolkit';
import { currencyConversion } from '../../Utils/Utils';


const initialState = {
  currentStep: 0,
  selectedCountry: "",
  selectedCurrency: "",
  error: false,
  errorMessage: "",
  selectedMetal:"",
  selectedMaterial:{},
  additiveMaterials:{
    "Stainless Steel 316L":  2800,
    "Stainless Steel 17-4PH": 3000,
    "Maraging Steel 300": 2800,
    "AlSi10mg": 4200 ,
    "Titanium Ti64": 25000,
    "Cobalt Chrome": 6800,
    "Cu-Cr-zr": 8000,
    "Nickel Alloy IN625": 1800,
  },
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
    },
    setError(state,action){
      state.error = action.payload;
    },
    setErrorMessage(state,action){
      state.errorMessage = action.payload
    },
    setSelectedMetal(state,action){
      state.selectedMetal = action.payload;
    },
    setSelectedMaterial(state,action){
      console.log(state);
       console.log(action.payload);
       const{material ,cost, selectedMaterialCost} = action.payload;
       state.selectedMaterial[material] = cost !== undefined ? cost : selectedMaterialCost;
    },
    deleteSelectedMaterial(state,action){
        delete state.selectedMaterial[action.payload];
    },
    setAdditiveMaterial(state,action){
      const{additiveMaterial,cost} = action.payload;
      console.log(action.payload);
      console.log(state.additiveMaterials[additiveMaterial]);
      state.additiveMaterials[additiveMaterial] = cost;
    }
  }
});

export const { setCurrentStep, 
               setSelectedCountry,
               setSelectedCurrency,
               setError,
               setErrorMessage,
               setSelectedMaterial,
               setSelectedMetal,
               deleteSelectedMaterial,
               setAdditiveMaterial
              } = onboardingSlice.actions;
export default onboardingSlice.reducer;
