import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymbol } from "../../Utils/Utils";
import { updateInput, calculateMHR, resetForm,closeDialog,setSelectedMachine,setshowDialog} from "../../Store/Slice/mhrSlice";
import styles from "./mhrCalculator.module.css";
import { setError, setErrorMessage } from "../../Store/Slice/onboardingSlice";

export const MhrCalculator = (props) => {

    const dispatch = useDispatch();
    const { inputs} = useSelector((state) => state.mhrCalculator);
    const selectedCurrency = useSelector((state) => state.onboarding.selectedCurrency);
    const error = useSelector((state) => state.onboarding.error);
    const errorMessage = useSelector((state) => state.onboarding.errorMessage);
    const selectedMachine = useSelector((state)=>state.mhrCalculator.selectedMachine)

    const handleChange = (e) => {
        if(!selectedMachine){
          dispatch(setError(true));
          dispatch(setErrorMessage("Please select a machine"));
          return
        }
          const { id, value } = e.target;
          const updatedInputs = {
              ...props.machinesMhr[selectedMachine].inputs, 
              [id]: value,
          };
          props.setMachinesMhr((prevMachinesMhr) => ({
              ...prevMachinesMhr,
              [selectedMachine]: {
                  ...prevMachinesMhr[selectedMachine],
                  inputs: updatedInputs,
              },
          }));
          dispatch(updateInput({ name: id, value: parseFloat(value) , machine: selectedMachine}));
    };

    const handleSubmit = (e) => {
       console.log('submit');
      e.preventDefault();
      const inputs = props.machinesMhr[selectedMachine].inputs

      const requiredFields = [
        'machineCost', 'paybackPeriod', 'utilization', 'serviceCostPercentage',
        'buildingRent', 'consumables', 'manpowerCost', 'inertGasCost',
        'avgFloodRate', 'powerConsumption'
    ];

    const allInputsEntered = requiredFields.every(field => inputs[field] !== '' && inputs[field] !== null && inputs[field] !== undefined);

    if (!allInputsEntered) {
        dispatch(setError(true));
        dispatch(setErrorMessage("Please fill all the fields"));
        document.getElementById("mhr").scrollTo(0, 0);
        return;
    }

        console.log(inputs);
        dispatch(setError(false));
        dispatch(setErrorMessage(""));
        const fixedCostPerYear = inputs.machineCost / inputs.paybackPeriod;
        const hoursOfOperation = 365 * 24 * (inputs.utilization / 100);
        const fixedCostPerHour = fixedCostPerYear / hoursOfOperation;
        console.log(fixedCostPerHour);
  
        const serviceCost = inputs.machineCost * (inputs.serviceCostPercentage / 100);
        console.log(serviceCost);
        const maintenanceCost = serviceCost / hoursOfOperation;
        console.log(maintenanceCost);
        const buildingRentPerHour = inputs.buildingRent / hoursOfOperation;
        console.log(buildingRentPerHour);
        const consumablesperHour = inputs.consumables / hoursOfOperation;
        console.log(consumablesperHour);
        const manpowerCostPerHour = inputs.manpowerCost / hoursOfOperation;
        console.log(manpowerCostPerHour);
        const inertGasCostPerHour =
          inputs.inertGasCost * inputs.avgFloodRate * 60;
        console.log(inertGasCostPerHour);
  
        const operatingCosts =
          parseFloat(maintenanceCost) +
          parseFloat(inputs.powerConsumption) +
          parseFloat(buildingRentPerHour)+
          parseFloat(consumablesperHour)+
          parseFloat(manpowerCostPerHour) +
          parseFloat(inertGasCostPerHour);
  
        const totalCost = parseFloat(fixedCostPerHour) + parseFloat(operatingCosts);
        console.log(totalCost);
  
        const updatedResults = {
          ...props.machinesMhr[selectedMachine].results, 
          fixedCostPerHour: parseFloat(fixedCostPerHour.toFixed(2)),
          operatingCosts: parseFloat(operatingCosts.toFixed(2)),
          totalCost: parseFloat(totalCost.toFixed(2)),
      };
  
        props.setMachinesMhr((prevMachinesMhr) => ({
          ...prevMachinesMhr,
          [selectedMachine]: {
              ...prevMachinesMhr[selectedMachine],
              results: updatedResults,
          },
      }));
        dispatch(setshowDialog());
      // dispatch(calculateMHR({machine:selectedMachine}));
    };

    const handleResetForm = (selectedMachine,e) =>{
      // dispatch(resetForm(selectedMachine));
    }

    const handleselectMachine = (machine) =>{
      dispatch(setError(false));
      dispatch(setErrorMessage(""));
      dispatch(setSelectedMachine(machine));
    }


    const fixedCostsArray = [
          {
            label: "machineCost",
            title: "Machine Cost",
            unit: "",
          },
          {
            label: "paybackPeriod",
            title: "Period of payback",
            unit: "yrs",
          },
          {
            label: "utilization",
            title: "Utilization of machine",
            unit: "%",
          },
        ];
      
        const operatingCostsArray = [
          {
            label: "serviceCostPercentage",
            title: "Service cost as a % of list price",
            unit: "%",
          },
          {
            label: "powerConsumption",
            title: "Power consumption cost per hour",
            unit: "",
          },
          {
            label: "buildingRent",
            title: "Building rent per year",
            unit: "",
          },
          {
            label: "consumables",
            title: "Consumables cost per year(Recoater Blade, Filters, etc.)",
            unit: "",
          },
          {
            label: "manpowerCost",
            title: "Annual manpower cost on machine operation",
            unit: "",
          },
          {
            label: "inertGasCost",
            title: "Inert gas cost per liter",
            unit: "",
          },
          {
            label: "avgFloodRate",
            title: "Avg flood rate while printing and flooding (liters per min)",
            unit: "L",
          },
        ];

    const InputComponent = ({ label, title, unit }) => {
        return (
            <div className={styles.inputGroup}>
                <label htmlFor={label}>{title}</label>
                <div style={{ display: "flex" }}>
                    <div className={`${unit ? "" : styles.currencySymbol}`}>
                        {unit === "" ? getCurrencySymbol(selectedCurrency) : ""}
                    </div>
                    <input
                        type="number"
                        id={label}
                        value={props.machinesMhr[selectedMachine]?.inputs[label] || ""}
                        step={0.01}
                        placeholder={unit ? `In ${unit}` : ""}
                        onChange={handleChange}
                        className={styles.mhrInput}
                        style={unit ? { paddingLeft: "10px", width: "78%" } : { paddingLeft: "23px" }}
                        required
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.mhrContainer}>
            <div className={styles.inputContainer}>
                <form onSubmit={handleSubmit} name="mhr">
                   <div className={styles.mhrHeaderContainer}>
                        <span>MHR Calculator</span>
                        <hr  style={{ color: "#c6c6c6", width: "550px", marginLeft: "1px" }}></hr>
                        <div className={styles.selectMachineContainer}>
                          <span>Select a machine</span>
                          <select
                              value={selectedMachine}
                              onChange={(e)=>handleselectMachine(e.target.value)}
                              className={!selectedMachine ? styles.placeholderOption : ""}
                              style={{
                                border: error && !selectedMachine ? "2px solid #D12323" : "",
                              }}
                              required
                           >
                              <option value="" className={styles.placeholderOption}>
                                Select an option
                              </option>
                              {Object.keys(props.machinesMhr).map((machineName)=>(
                                <option key={machineName} value={machineName} className={styles.options}>
                                  {machineName}
                                </option>
                              ))}
                            </select>
                            {error && (
                              <div className={styles.errorMessage}>
                                  {errorMessage}
                              </div>
                            )}
                        </div>
                  </div>
                    <div className={styles.fixedCostContainer}>
                        <div className={styles.fixedCostHeading}>Fixed Costs</div>
                        <hr style={{ color: "#c6c6c6", width: "550px", marginLeft: "1px" }}></hr>
                        {/* <button onClick={()=>handleResetForm(selectedMachine)}>Reset</button> */}
                                    <div className={styles.fixedCosts}>
                                    {fixedCostsArray.map((input, index) => {
                                        return (
                                          <>
                                            {InputComponent({
                                              key: index,
                                              label: input.label,
                                              title: input.title,
                                              unit: input.unit,
                                            })}
                                          </>
                                        );
                                      })}
                                    </div>
                                </div>

                    <div className={styles.operatingCostContainer}>
                        <div className={styles.operatingCostHeading}>Operating Costs</div>
                        <hr style={{ color: "#c6c6c6", width: "550px", marginLeft: "1px" }}></hr>
                        <div className={styles.operatingCosts}>
                        {operatingCostsArray.map((input, index) => {
                            return (
                              <>
                                {InputComponent({
                                  key: index,
                                  label: input.label,
                                  title: input.title,
                                  unit: input.unit,
                                })}
                              </>
                            );
                          })}
                        </div>
                    </div>
                    <button type="submit" className={styles.calculate} onClick={(e)=>handleSubmit(e)}>
                        Calculate MHR
                    </button>
                    {console.log(props.machinesMhr)}
                </form>
            </div>
        </div>
    );
};

