import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./page1.module.css";
import {
	setCurrentStep,
	setSelectedCountry,
	setSelectedCurrency,
	setError,
	setErrorMessage,
} from "../../Store/Slice/onboardingSlice";
import { CustomPrimaryBtn } from "../../Components/Buttons/PrimaryButton/PrimaryButton";

export const Page1 = () => {
	const dispatch = useDispatch();

	const countries = ["India", "USA", "UK", "Japan"];
	const currencies = ["INR", "USD", "EUR", "YEN"];

	const currentStep = useSelector((state) => state.onboarding.currentStep);
	const selectedCountry = useSelector(
		(state) => state.onboarding.selectedCountry
	);
	const selectedCurrency = useSelector(
		(state) => state.onboarding.selectedCurrency
	);
	const error = useSelector((state) => state.onboarding.error);
	const errorMessage = useSelector((state) => state.onboarding.errorMessage);

	const handleCountryChange = (e) => {
		dispatch(setSelectedCountry(e.target.value));
	};

	const handleCurrencyChange = (e) => {
		dispatch(setSelectedCurrency(e.target.value));
	};

	const handleNext = () => {
		if (selectedCountry && selectedCurrency) {
			dispatch(setCurrentStep(currentStep + 1));
			dispatch(setError(false));
			dispatch(setErrorMessage(""));
		} else {
			dispatch(setError(true));
			dispatch(setErrorMessage("This field is required."));
		}
	};

	return (
		<>
			<div className={styles.mainContent}>
				<p className={styles.onboardingTxt}>
					Hi there! To tailor your onboarding experience, please specify your
					material and machine preferences in next steps. Your selection will guide our
					platform to offer relevant insights and resources. Let's advance
					manufacturing together.
				</p>
				<div className={styles.dropdownContainer}>
					<label>Select Country</label>
					<select
						value={selectedCountry}
						onChange={handleCountryChange}
						className={!selectedCountry ? styles.placeholderSelected : ""}
						required
						style={{
							border: error && !selectedCountry ? "2px solid #D12323" : "",
						}}
					 >
						<option value="" className={styles.placeholderOption}>
							Select an option
						</option>
						{countries.map((country) => (
							<option key={country} value={country} className={styles.options}>
								{country}
							</option>
						))}
					</select>
					{error && !selectedCountry && (
						<div className={styles.errorMessage}>{errorMessage}</div>
					)}
				</div>
				<div className={styles.radioContainer}>
					<p>Select Currency</p>
					<div
						className={styles.radioOptionsContainer}
						style={{
							border: error && !selectedCurrency ? "2px solid #D12323" : "",
						}}
					>
						{currencies.map((currency) => (
							<label key={currency}>
								<input
									type="radio"
									value={currency}
									checked={selectedCurrency === currency}
									onChange={handleCurrencyChange}
									required
								/>
								{currency}
							</label>
						))}
					</div>
					{error && !selectedCurrency && (
						<div className={styles.errorMessage}>{errorMessage}</div>
					)}
				</div>
			</div>
			<div className={styles.nextButtonContainer}>
				<CustomPrimaryBtn handleClick={() => handleNext()} buttonText="Next" />
			</div>
		</>
	);
};
