import React, { useState } from "react";
import styles from "./page3.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from "../../Store/Slice/onboardingSlice";
import { CustomPrimaryBtn } from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import { CustomSecondaryBtn } from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import { getCurrencySymbol, currencyConversion } from "../../Utils/Utils";
import { setAdditiveMaterial } from "../../Store/Slice/onboardingSlice";
import InfoButton from "../../assets/images/Info.svg";

export const Page3 = () => {
    const dispatch = useDispatch();
    const selectedMaterial = useSelector((state) => state.onboarding.selectedMaterial);
    const additiveMaterials = useSelector((state) => state.onboarding.additiveMaterials);
    const currency = useSelector((state) => state.onboarding.selectedCurrency);
    const currentStep = useSelector((state)=> state.onboarding.currentStep);
    console.log(selectedMaterial);

    const [editableCosts, setEditableCosts] = useState({});

    const handleChange = (e, material) => {
        const cost =e.target.value;
		const maxCost = 100000;
		if ((!cost.startsWith("0") || cost === "0") && cost <= maxCost) {
			setEditableCosts({
				...editableCosts,
				[material]: cost,
			});
		}
        dispatch(setAdditiveMaterial({additiveMaterial:material,cost:parseFloat(cost)}));
    };


	const handleNext = () => {
        dispatch(setCurrentStep(currentStep + 1));
	};

	const handlePrev = () => {
		dispatch(setCurrentStep(currentStep - 1));
	};

    // const handleChange = (cost,additiveMaterial) =>{
    //     const newCost = parseFloat(cost);
    //     dispatch(setAdditiveMaterial({additiveMaterial,newCost}))
    // }


    const renderContent = () =>{
        const rowsToRender = [];
        Object.keys(additiveMaterials).map((material) =>{
            const cost = editableCosts[material] !== undefined ? editableCosts[material] : currencyConversion(additiveMaterials[material], currency);
                        rowsToRender.push(
                            <tr key={material}>
                                <td>{material}</td>
                                <td>
                                    <div style={{ display: "flex" }}>
                                        <div className={styles.currencySymbol}>
                                            {getCurrencySymbol(currency)}
                                        </div>
                                        <input
                                                type="number"
                                                value={cost}
                                                className={styles.inputCost}
                                                onChange={(e) => handleChange(e, material)}
                                                
                                        />
                                            {console.log(additiveMaterials)}
									</div>
                                </td>
                            </tr>
                        );
                    })

        return rowsToRender;
    }
    
    return (
        <>  
        <div className={styles.additiveMaterialsHeader}>
            <span>
                List of Additive materials
            </span>
        </div>
        <div className={styles.tableContainer}>
                <table className={styles.table} rules="rows">
                <thead>
                    <tr>
                        <th>Additive Material</th>
                        <th>
                            <div className={styles.costTableHeader}>
                                <span>Cost per kg</span>
                                <div className={styles.costTableInfoButton}>
                                                <img
                                                    src={InfoButton}
                                                    alt="info"
                                                    style={{ width: "13px", height: "13px" }}
                                                />
                                                <span className={styles.tooltipText}>Cost is editable</span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                   {renderContent()}
                </tbody>
            </table>
        </div>
        <div className={styles.bottomContainer}>
            <CustomSecondaryBtn
                handleClick={() => handlePrev()}
                buttonText="Back"
            />
            <CustomPrimaryBtn handleClick={() => handleNext()} buttonText="Next"/>
        </div>
        {console.log(additiveMaterials)}
        </>
    );
};
