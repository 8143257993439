export const getCurrencySymbol = (code) => {
	const currencySymbols = {
		USD: "$",
		INR: "₹",
		EUR: "€",
		YEN: "¥",
	};
	return currencySymbols[code] || "";
};

//Currency Converter
export const currencyConversion = (value, currencyType) => {
	if(currencyType === "INR"){
		return value;
	}

		const ExchangeRate = {
			USD: 0.012, // 1 INR = 0.012 USD
			YEN: 1.76, // 1 INR = 1.76 YEN
			EUR: 0.011, // 1 INR = 0.011 EUR
			INR: 1,
		};
	
		const convertedValue = (
			parseFloat(value) * ExchangeRate[currencyType]
		).toFixed(2);
	
		return convertedValue;
};
