import './App.css';
import {Onboarding} from './Pages/Onboarding/Onboarding';


function App() {
  return (
    <div>
      <Onboarding /> 
    </div>
  );
}

export default App;
