import React from 'react';
import styles from './progressbar.module.css';

export const ProgressBar = ({ steps, currentStep }) => {

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div className={styles.progressTrack}></div>
        {steps.map((step, index) => (
          <div key={index} className={styles.stepContainer}>
            <div
              className={`${styles.progressStep} ${index < currentStep ? styles.completed : ''} ${
                index === currentStep ? styles.current : ''
              }`}
            >
              {index < currentStep ? (
                <span className={styles.tick}>✓</span>
              ) : (
                <span className={styles.dot}></span>
              )}
            </div>
            <div className={styles.stepLabel}>{step}</div>
          </div>
        ))}
      </div>
    </div>
  );
};










