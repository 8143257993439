import React, { useEffect, useState } from "react";
import styles from "./page2.module.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomPrimaryBtn } from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import { CustomSecondaryBtn } from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import {
	setCurrentStep,
	setSelectedMetal,
	setSelectedMaterial,
	deleteSelectedMaterial,
	setError,
	setErrorMessage,
} from "../../Store/Slice/onboardingSlice";
import deleteButton from "../../assets/images/Delete.svg";
import CloseButton from "../../assets/images/Vector.svg";
import InfoButton from "../../assets/images/Info.svg";
import { getCurrencySymbol, currencyConversion } from "../../Utils/Utils";
import DeleteWarningButton from "../../assets/images/DeleteWarningButton.svg"

export const Page2 = () => {
	const dispatch = useDispatch();
	const currentStep = useSelector((state) => state.onboarding.currentStep);
	const currency = useSelector((state) => state.onboarding.selectedCurrency);
	const selectedMetal = useSelector((state) => state.onboarding.selectedMetal);
	const selectedMaterial = useSelector(
		(state) => state.onboarding.selectedMaterial
	);
	const error = useSelector((state) => state.onboarding.error);
	const errorMessage = useSelector((state) => state.onboarding.errorMessage);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [materialtobeDeleted , setMaterialToBeDeleted] = useState(false);

	// Cost in rupees
	const materialsList = {
		Nickel: {
			"Ni 200": 900,
			"Ni 625": 1200,
			"Ni 718": 1300,
			"Ni 825": 1000,
			"Ni 600": 1000,
		},
		Aluminium: {
			"Al 6061": 340,
			"Al 7075": 400,
			"Al 5083": 360,
			"Al 2024": 380,
			"Al 6063": 360,
		},
		LowCarbonSteel: {
			"Low Carbon Steel 1018": 60,
			"Low Carbon Steel A36": 60,
			"Low Carbon Steel 1020": 60,
			"Low Carbon Steel 1045": 60,
			"Low Carbon Steel 12L14": 60,
		},
		ToolSteel: {
			"Tool Steel D2": 80,
			"Tool Steel A2": 80,
			"Tool Steel O1": 75,
			"Tool Steel M2": 90,
			"Tool Steel S7": 75,
		},
		StainlessSteel: {
			"SS 304": 60,
			"SS 316": 60,
			"SS 430": 60,
			"SS 201": 60,
			"SS17-4PH": 75,
		},
		Titanium: {
			"Ti-6Al-4V": 8000,
			"Ti-6Al-4V ELI": 7800,
			"Ti Grade 2": 7200,
			"Ti Grade 5": 7800,
			"Ti Grade 9": 7800,
		},
	};

	const handleMetalSelect = (metal) => {
		console.log(metal);
		dispatch(setSelectedMetal(metal));
	};

	const handleMaterialSelect = (material,e) => {
		const selectedMaterialCost = materialsList[selectedMetal]
		? materialsList[selectedMetal][material]
		: "";
	
		dispatch(
			setSelectedMaterial({
				material,
				selectedMaterialCost: currencyConversion(selectedMaterialCost,currency)
				// selectedMaterialCost,
			})
		);
		dispatch(setError(false));
		dispatch(setErrorMessage(""));
	};

	const handleCostChange = (material,e)=>{
		const cost = e.target.value;
		console.log(cost);
		const maxCost = 100000;
			if ((!cost.startsWith("0") || cost === 0) && cost <= maxCost){
				dispatch(
					setSelectedMaterial({
						material,
						cost
					})
				);
			}
	}

	const handleRemoveMaterial = (material) => {
		console.log(material);
		dispatch(deleteSelectedMaterial(material));
		setIsDeleteDialogOpen(false);
	};

	const handleDelete = (material)=>{
		setIsDeleteDialogOpen(true);
		setMaterialToBeDeleted(material);
	}

	const handleNext = () => {
		if (Object.keys(selectedMaterial).length === 0) {
			dispatch(setError(true));
			dispatch(setErrorMessage("Please select minimum one material"));
		} else {
			dispatch(setCurrentStep(currentStep + 1));
		}
	};

	const handlePrev = () => {
		dispatch(setCurrentStep(currentStep - 1));
	};

	const isMaterialSelected = (material) => {
		return selectedMaterial.hasOwnProperty(material);
	};

	const filteredMaterials = Object.keys(materialsList).map((metal) =>
		Object.keys(materialsList[metal]).filter(
			(material) => !isMaterialSelected(material)
		)
	);

	const isEmpty = filteredMaterials.every((arr) => arr.length === 0);


	return (
		<>
			<div className={styles.dropdown}>
				<span className={styles.inputLabel}>Select Material</span>
				<div
					className={styles.input}
					style={{
						border: error ? "2px solid #D12323" : "",
					}}
				>
					Select Material
				</div>
				<div className={styles.dropdownContent}>
					{Object.keys(materialsList).map((metal) => (
						<div
							key={metal}
							className={styles.hover}
							onClick={() => handleMetalSelect(metal)}
							onMouseOver={() => handleMetalSelect(metal)}
						>
							<div>{metal}</div>
						</div>
					))}
				</div>
				{selectedMetal && (
					<div className={styles.subDropdownContent}>
						{Object.keys(materialsList[selectedMetal]).filter(
							(material) => !isMaterialSelected(material)
						).length === 0 ? (
							<div className={styles.noAlloysFound}>
								<span>No Alloys found</span>
							</div>
						) : (
							Object.keys(materialsList[selectedMetal])
								.filter((material) => !isMaterialSelected(material))
								.map((material) => (
									<div
										key={material}
										className={styles.materialName}
										onClick={(e) => handleMaterialSelect(material,e)}
									>
										<div>
											<span>{material}</span>
										</div>
									</div>
								))
						)}
					</div>
				)}
			</div>
			{/* Table */}
			{Object.keys(selectedMaterial).length > 0 && (
				<div className={styles.tableContainer}>
					<table className={styles.table} rules="rows">
						<thead>
							<tr>
								<th>Conventional Material</th>
								<th>
									<div className={styles.costTableHeader}>
										<span>Cost per kg </span>
										<div className={styles.costTableInfoButton}>
											<img
												src={InfoButton}
												alt="info"
												style={{ width: "13px", height: "13px" }}
											/>
											<span className={styles.tooltipText}>Cost is editable</span>
										</div>
									</div>
								</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(selectedMaterial).map((material) => {
								const metal = Object.keys(materialsList).find((metal) =>
									materialsList[metal].hasOwnProperty(material)
								);
								const originalCost = selectedMaterial[material];
								const convertedCost = currencyConversion(originalCost,currency);
								return (
									<tr key={material}>
										<td>{`${metal} - ${material}`}</td>
										<td>
											<div style={{ display: "flex" }}>
												<div className={styles.currencySymbol}>
													{getCurrencySymbol(currency)}
												</div>
												<input
													type="number"
													 className={styles.inputCost}
													 value={originalCost}
													 onChange={(e) =>handleCostChange(material,e)}
												  />

												
											</div>
										</td>
										<td>
											<button
												className={styles.deleteButton}
												onClick={()=>handleDelete(material)}
											 >
												<img src={deleteButton} alt="delete" />
											</button>
										</td>
									</tr>
								);
							})}

							{console.log(
								Object.keys(materialsList).map((metal) =>
									Object.keys(materialsList[metal]).filter(
										(material) => !isMaterialSelected(material)
									)
								)
							)}

							{!isEmpty ? (
								<tr
									style={{ height: "50px" }}
									onClick={() => setIsDialogOpen(true)}
								>
									<td colSpan={3}>
										<span className={styles.popUp}>
											View list of other Conventional materials
										</span>
									</td>
								</tr>
							) : (
								""
							)}
						</tbody>
					</table>
				</div>
			)}

			{/* Conventional material table */}
			{isDialogOpen && (
				<div className={styles.overlay}>
					<div className={styles.dialog}>
						<button
							className={styles.closeButton}
							onClick={() => setIsDialogOpen(false)}
						>
							<img src={CloseButton} alt="close" />
						</button>
						<div className={styles.dialogTableContainer}>
							<table className={styles.table} rules="rows">
								<thead>
									<tr>
										<th>Conventional Material</th>
										<th>Cost per kg</th>
									</tr>
								</thead>
								<tbody>
									{Object.keys(materialsList).map((metal) =>
										Object.keys(materialsList[metal])
											.filter((material) => !isMaterialSelected(material))
											.map((material) => (
												<tr key={material}>
													<td>{`${metal} - ${material}`}</td>
													<td>
														<div style={{ display: "flex" }}>
															<div className={styles.currencySymbol}>
																{getCurrencySymbol(currency)}
															</div>
															<div className={styles.inputCost} style={{width:"85px",textAlign:"center"}}>
																{currencyConversion(
																	materialsList[metal][material],
																	currency
																)}
															</div>
														</div>
													</td>
												</tr>
											))
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
			{isDeleteDialogOpen && (
				<div className={styles.deleteOverlay}>
					<div className={styles.deleteDialog}>
								<div className={styles.deleteWarningIcon}>
									<img src={DeleteWarningButton} alt="Warning"/>
									<span>Delete</span>
								</div>
								<p>Are you sure you want to delete the material ?</p>
								<div className={styles.buttonsContainer}>
									<button onClick={()=>setIsDeleteDialogOpen(false)} style={{color:""}}>Cancel</button>
									<button onClick={(material)=>handleRemoveMaterial(materialtobeDeleted)} style={{color:"white",backgroundColor:"#FF3225"}} >Delete</button>
								</div>
						</div>
				</div>
			)
			}
			{error ? <div className={styles.errorMessage}>{errorMessage}</div> : ""}
			<div className={styles.bottomContainer}>
				<CustomSecondaryBtn
					handleClick={() => handlePrev()}
					buttonText="Back"
				/>
				<CustomPrimaryBtn handleClick={() => handleNext()} buttonText="Next" />
				{console.log(selectedMetal)}
				{console.log(selectedMaterial)}
			</div>
		</>
	);
};
