import React, { useState } from "react";
import styles from "./backbutton.module.css";
import backButtonIcon  from "../../assets/images/back_icon.svg";
import CloseButton from "../../assets/images/Vector.svg"
import WarningButton from "../../assets/images/WarningButton.svg"

export const Backbutton = () => {

    const [isDialogOpen , setIsDialogOpen] = useState(false);
    return(
        <>
            <div className={styles.backButton}>
                <div className={styles.backButtonIcon} onClick={()=>setIsDialogOpen(true)}>
                    <img src={backButtonIcon} alt="Back to Home"  />
                </div>
                <p>Back Home</p>
            </div>
            {isDialogOpen && (
			 <div className={styles.overlay}>
					<div className={styles.dialog}>
                            <div className={styles.exitOnboardingIcon}>
                                <img src={WarningButton} alt="Warning" />
                                <span>Exit Onboarding</span>
                            </div>
                            <p>Are you sure you want to exit the onboarding process ?</p>
                            <div className={styles.buttonsContainer}>
                                <button >Exit</button>
                                <button onClick={()=>setIsDialogOpen(false)} style={{color:"white",backgroundColor:"#FFD525"}}>Cancel</button>
                            </div>
					</div>
				</div>
			)}
        </>
    );
}