import React from 'react';
import amExplorerLogo from "../../assets/images/mainLogo.svg";
import styles from "./header.module.css"

export const Header = () =>{

    return(
        <>
            <div className={styles.headerContainer}>
                <img src={amExplorerLogo} alt="AM-Explorer" />
                <div className={styles.headerLine}></div>
            </div>
        </>
    );
}