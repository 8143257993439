import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	inputs: {
		machineCost: null,
		paybackPeriod: null,
		utilization: null,
		serviceCostPercentage: null,
		powerConsumption: null,
		buildingRent: null,
		consumables: null,
		manpowerCost: null,
		inertGasCost: null,
		avgFloodRate: null,
	},
	results: {
		fixedCostPerHour: 0,
		operatingCosts: 0,
		totalCost: 0,
	},

	selectedMachine: "",

	machineMhr: {
		ifusion150: {
			MHR: 1500,
			dimensions: "150 x 150 x 200",
			inputs: {
				machineCost: null,
				paybackPeriod: null,
				utilization: null,
				serviceCostPercentage: null,
				powerConsumption: null,
				buildingRent: null,
				consumables: null,
				manpowerCost: null,
				inertGasCost: null,
				avgFloodRate: null,
			},
			results: {
				fixedCostPerHour: 0,
				operatingCosts: 0,
				totalCost: 0,
			},
		},
		ifusion325: {
			MHR: 2800,
			dimensions: "150 x 150 x 200",
			inputs: {
				machineCost: null,
				paybackPeriod: null,
				utilization: null,
				serviceCostPercentage: null,
				powerConsumption: null,
				buildingRent: null,
				consumables: null,
				manpowerCost: null,
				inertGasCost: null,
				avgFloodRate: null,
			},
			results: {
				fixedCostPerHour: 0,
				operatingCosts: 0,
				totalCost: 0,
			},
		},
		ifusion450: {
			MHR: 6500,
			dimensions: "150 x 150 x 200",
			inputs: {
				machineCost: null,
				paybackPeriod: null,
				utilization: null,
				serviceCostPercentage: null,
				powerConsumption: null,
				buildingRent: null,
				consumables: null,
				manpowerCost: null,
				inertGasCost: null,
				avgFloodRate: null,
			},
			results: {
				fixedCostPerHour: 0,
				operatingCosts: 0,
				totalCost: 0,
			},
		},
	},
	showDialog: false,
};

const mhrSlice = createSlice({
	name: "mhrCalculator",
	initialState,
	reducers: {
		setSelectedMachine(state,action){
			state.selectedMachine = action.payload;
		},
		updateMachineMhr(state, action) {
			const { name, field, value } = action.payload;
			state.machineMhr[name][field] = value;	
		},

		updateInput(state, action) {
			const { name, value ,machine} = action.payload;
			console.log(name,value);
			// state.inputs[name] = value;
			state.machineMhr[machine].inputs[name]= value;
		},

		calculateMHR(state,action) {
			console.log(action.payload);
			const {machine} = action.payload;
			const machineName = state.machineMhr[machine];
			console.log(machineName);
			const inputs = machineName.inputs;
			const fixedCostPerYear = inputs.machineCost / inputs.paybackPeriod;
			const hoursOfOperation = 365 * 24 * (inputs.utilization / 100);
			const fixedCostPerHour = fixedCostPerYear / hoursOfOperation;

			const serviceCost =
				inputs.machineCost * (inputs.serviceCostPercentage / 100);
			const maintenanceCost = serviceCost / hoursOfOperation;
			const buildingRentPerHour = inputs.buildingRent / hoursOfOperation;
			const consumablesperHour = inputs.consumables / hoursOfOperation;
			const manpowerCostPerHour = inputs.manpowerCost / hoursOfOperation;
			const inertGasCostPerHour =
				inputs.inertGasCost * inputs.avgFloodRate * 60;

			const operatingCosts =
				maintenanceCost +
				inputs.powerConsumption +
				buildingRentPerHour +
				consumablesperHour +
				manpowerCostPerHour +
				inertGasCostPerHour;

			const totalCost = fixedCostPerHour + operatingCosts;
			console.log(totalCost);

			machineName.results = {
				fixedCostPerHour: parseFloat(fixedCostPerHour.toFixed(2)),
				operatingCosts: parseFloat(operatingCosts.toFixed(2)),
				totalCost: parseFloat(totalCost.toFixed(2)),
			};
			state.showDialog = true;
		},
		resetForm(state,action) {
			console.log("reset");
			const selectedMachine = action.payload;
			state.machineMhr[selectedMachine].inputs = initialState.inputs;
			state.showDialog = false;
		},
		closeDialog(state) {
			state.showDialog = false;
		},
		setshowDialog(state){
			state.showDialog = true;
		}
	},
});

export const {
	updateMachineMhr,
	updateInput,
	calculateMHR,
	resetForm,
	closeDialog,
	setSelectedMachine,
	setshowDialog
} = mhrSlice.actions;

export default mhrSlice.reducer;
