import React, { useEffect, useState } from "react";
import styles from "./page4.module.css";
import { useDispatch, useSelector } from "react-redux";
import InfoButton from "../../assets/images/InfoNew.svg";
import { CustomSecondaryBtn } from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import { CustomPrimaryBtn } from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import { setCurrentStep } from "../../Store/Slice/onboardingSlice";
import { MhrCalculator } from "./MhrCalculator";
import { getCurrencySymbol, currencyConversion } from "../../Utils/Utils";
import { closeDialog, updateMachineMhr } from "../../Store/Slice/mhrSlice";
import copyButton from "../../assets/images/Copy.svg";
import CloseButton from "../../assets/images/Vector.svg";


export const Page4 = () => {
	const dispatch = useDispatch();
	const currentStep = useSelector((state) => state.onboarding.currentStep);
	const selectedCurrency = useSelector(
		(state) => state.onboarding.selectedCurrency
	);
	const { machineMhr, showDialog, selectedMachine } = useSelector(
		(state) => state.mhrCalculator
	);

	const machineConvertedMhr = Object.keys(machineMhr).reduce((acc, key) => {
		acc[key] = {
			...machineMhr[key],
			MHR: currencyConversion(machineMhr[key].MHR, selectedCurrency),
		};
		return acc;
	}, {});


	const [showCalculator, setShowCalculator] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	const [machinesMhr, setMachinesMhr] = useState({ ...machineConvertedMhr });
	console.log(showDialog);
	console.log(machinesMhr);

	const updateMachineMhr = ({ name, field, value }) => {
		console.log(name);
		setMachinesMhr((prev) => {
			return {
				...prev,
				[name]: {
					...prev[name],
					[field]: value,
				},
			};
		});
	};

	const handlePrev = () => {
		dispatch(setCurrentStep(currentStep - 1));
	};


	const CardRenderer = ({ machineName, dimensions, mhr }) => {
		console.log(machineName);

		return (
			<div className={styles.card}>
				<div className={styles.machineNameContainer}>
					<span>Machine: </span>
					<div className={styles.machineName}>
						<span>
							{machineName} ({dimensions} mm)
						</span>
					</div>
				</div>

				<div className={styles.mhrInputContainer}>
					<span style={{ marginLeft: "5px", marginBottom: "10px" }}>MHR: </span>
					<div style={{ display: "flex" }}>
						<div className={styles.currencySymbol}>
							{getCurrencySymbol(selectedCurrency)}
						</div>

						<input
							type="number"
							value={mhr}
							onChange={(e) => {
								updateMachineMhr({
									name: machineName,
									field: "MHR",
									value: e.target.value,
								});
							}}
							className={styles.mhrCost}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleCloseDialog = () => {
		dispatch(closeDialog());
		enableScroll();
	};

	const disableScroll = () => {
		document.getElementById("mhr").style.overflow = "hidden";
	};

	const enableScroll = () => {
		document.getElementById("mhr").style.overflowY = "auto";
		document.getElementById("mhr").style.overflowX = "hidden";
	};

	useEffect(() => {
		if (showDialog) {
			disableScroll();
			document.getElementById("mhr").scrollTo(0, 0);
		}
	}, [showDialog]);

	const copyToClipboard = () => {
		const textToCopy = machinesMhr[selectedMachine].results.totalCost;
		const textarea = document.createElement('textarea');
		textarea.value = textToCopy;
		document.body.appendChild(textarea);
		textarea.select();
		
		try {
			const successful = document.execCommand('copy');
			if (successful) {
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 2000);
			} else {
				console.error('Failed to copy: Copy command failed.');
			}
		} catch (err) {
			console.error('Failed to copy: ', err);
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		} finally{
			document.body.removeChild(textarea);
		}
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.machineCardsContainer}>
				<div className={styles.infoButton}>
						<img src={InfoButton} alt="info" />
						<span>To calculate MHR manually use Calculator</span>
				</div>
				{Object.keys(machinesMhr).map((name) =>
					CardRenderer({
						key: name,
						machineName: name,
						dimensions: machinesMhr[name].dimensions,
						mhr: machinesMhr[name].MHR,
					})
				)}
			</div>

			<div className={styles.mhrCalculator} id="mhr">
				<button
					className={styles.calculateMhrButton}
					onClick={() => setShowCalculator(true)}
				>
					Calculate Mhr
				</button>
				{showCalculator && (
					<MhrCalculator
						machinesMhr={machinesMhr}
						setMachinesMhr={setMachinesMhr}
					/>
				)}
				{console.log(machinesMhr)}
				{showDialog && (
					<div className={styles.overlay}>
						<div className={styles.dialog}>
							<div className={styles.content}>
								<p style={{ fontWeight: "bold" }}>
									Total MHR Cost:
									<span className={styles.totalCost}>
									    {console.log(machinesMhr[selectedMachine].results)}
										{" " +
											getCurrencySymbol(selectedCurrency) +
											" " +
											machinesMhr[selectedMachine].results.totalCost}
										<button onClick={() => copyToClipboard()}>
											<img src={copyButton} alt="copy" />
										</button>
										{isCopied && <div className={styles.copiedMessage}>Copied to clipboard</div>}
									</span>
								</p>
								<button
									className={styles.closeButton}
									onClick={() => handleCloseDialog()}
								>
									<img src={CloseButton} alt="close" />
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className={styles.bottomContainer}>
					<CustomSecondaryBtn
						handleClick={() => handlePrev()}
						buttonText="Back"
					/>
					<CustomPrimaryBtn
						buttonText="Submit"
					/>
			</div>
		</div>
	);
};
